html {
        font-weight: 400;
	line-height: 1.5em;
	font-size: 1em;
        margin: 0;
        background: #ffffff;
        color: #696b6e;
}

body {
	line-height: 1.5rem;
}


img {
        max-width: 100%;
        height: auto;
}

picture {
    display: block;
    overflow: hidden;
    text-align: center;
}

img:focus {
        outline: none;
}

section {
        position: relative;
}

button:hover {
        cursor: pointer;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #C7C7C7;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #C7C7C7;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #C7C7C7;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #C7C7C7;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #C7C7C7;
}

::placeholder { /* Most modern browsers support this now. */
   color:    #C7C7C7;
}

/** main layout **/
.section-container {
        position: relative;
}

.section-container .row-container > .container,
.section-container .row-container > .container-fluid {
        padding-top: 1rem;
        padding-bottom: 1rem;
}

.moduleTitle {
        margin-bottom: 20px;
}

.scroll-page:hover {
        cursor: pointer;
}

/*.scroll-page:before {*/
/*        font-family: 'FontAwesome';*/
/*        content: '\f107';*/
/*        font-size: 31px;*/
/*        color: #fff;*/
/*        text-shadow: 0 1px 2px rgba(0,0,0,0.3);*/
/*}*/

.copyright.fixed-section {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
}

section.header {
        z-index: 100;
}

.styled-container {
        padding: 15px;
}

.styled-container > .styled-header {
        margin: -15px -15px 0;
}

.styled-header {
        display: flex;
}

.styled-header .moduleTitle  {
        width: 100%;
        margin: 0;
        padding: 15px;
}

.xs-text {
        font-size: 0.8rem;
}

.sm-text {
        font-size: 1rem;
}

.md-text {
        font-size: 1.4rem;
}

.lg-text {
        font-size: 2.5rem;
}

.xlg-text {
        font-size: 4rem;
}

/** links **/
a, button {
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        text-decoration: none;
}

a:hover, a:focus {
        text-decoration: none;
}

a:focus, button:focus {
        text-decoration: none;
}

.cursor:hover {
        cursor: pointer;
}

.btn.focus, .btn:focus {
        outline: none !important;
        box-shadow: none !important;
        color: #fff;
}

.light-text.btn.btn-default {
        color: #696b6e;
}

/** custom urls */
.custom-urls {
        margin: 1rem 0;
        display: block;
}

.custom-urls .btn {
        margin: 0 0.4rem;
}

.custom-urls .btn:first-child {
        margin-left: 0;
}

.custom-urls .btn:last-child {
        margin-right: 0;
}

/** forms **/
input {
        outline: none !important;
}

/*body .form-control {
        border-width: 2px;
}*/

body .form-control:focus {
        outline: none;
        box-shadow: none;
}

/*body .input-group .form-control {
        height: auto;
        padding: 0.8rem 1rem;
        line-height: 1.5rem;
}*/

.form-inline .input-group .btn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
}

/* Video background */
.video-background {
        background: #000;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        /*z-index: -99;*/
        overflow: hidden;
}
.video-foreground,
.video-background iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
}

.video-foreground:after {
        content: '';
        background: #fff;
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
}

.video-foreground.loaded:after {
        opacity: 0;
}

/** helpers **/
.fix-to-top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
}

.text-separator {
        margin: 0 10px;
        border-left: 1px solid #ccc;
}

/* font weights */
.light {
        font-weight: 300;
}

.normal {
        font-weight: 400;
}

.strong {
        font-weight: 700;
}

/** sidebar **/
.sidebar {
        position: fixed;
        z-index: 999;
        width: 320px;
        top: 0;
        background: #fff;
        transition: all 0.3s ease 0s;
        min-height: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
}

.sidebar.left {
        left: -320px;
}

.sidebar.right {
        right: -320px;
}

.sidebar .row-container {
        padding-top: 15px;
}

.sidebar-opened .sidebar.right  {
        right: 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.sidebar-opened .sidebar.left  {
        left: 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.sidebar .menu {
        list-style: none;
}

.sidebar .menu li a {
    color: #fff;
    font-size: 18px;
    padding: 5px 0;
    display: block;
}

.sidebar .moduletable:first-child {
    padding-top: 0;
}
.sidebar .moduletable {
    padding: 15px 0;
}

.sidebar .moduletable .user-menu li {
    display: inline-block;
}

.sb-close-button {
    color: #fff;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 35px;
    cursor: pointer;
    z-index: 999;
}

.sb-close-button i.fa-angle-left:before {
    margin-left: -3px;
}

.wrapper-inner, #footer-wrapper {
    transition: all 0.3s ease 0s;
    position: relative;
}

.sidebar-opened .wrapper-inner, .sidebar-opened .footer-wrapper-inner {
    /*margin-right: 320px;*/
}

.sidebar-opened #header-wrapper:before,
.sidebar-opened #content-wrapper:before,
.sidebar-opened #footer-wrapper:before {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    content: '';
    z-index: 900;
}

/** modals **/
.modal-boxes .moduleTitle {
    margin: 0;
}

.modal-backdrop {
    opacity: 0.5;
}

.modal-backdrop {
    display: none !important;
}

.modal-open .modal-backdrop {
    display: block !important;
}

.modal-open .modal-boxes .modal {
    display: flex !important;
}

#top-a-row hr {
    border-color: #fff;
}
