$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1396px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1336px
) !default;

$gray-800: 	#686868 !default;
$blue:    	#2E6BA9 !default;
$warning:   #437ba5 !default;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 5)
), $spacers);
