html body {
	/*font-family: 'Montserrat', sans-serif !important;*/
	font-family: 'Roboto', sans-serif !important;
	font-weight: 500;
	letter-spacing: 0.5px;
	color: #727272;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

body.spacer-top #content-wrapper {
	padding-top: 70px;
}

a{
    color: #437ba5;
}

h1.line,
h2.line,
h3.line {
	padding: 0 0 20px;
	margin-bottom: 80px;
	position: relative;
}

h1.line:after,
h2.line:after,
h3.line:after {
	/* content: '';
	width: 45px;
	height: 2px;
	background: #f17e2d;
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -22.5px; */
}

h1.line.text-light:after,
h2.line.text-light:after,
h3.line.text-light:after {
	background-color: #fff;
}

h1.line.text-left:after,
h2.line.text-left:after,
h3.line.text-left:after {
	left: 0;
	margin-left: 0;
}

h4 {
	font-size: 1.1rem;
	font-weight: 700;
	line-height: 1.5rem;
}

b {
	font-weight: 500;
}

table{
	font-size: 12px;
}

#showcase-row {
	overflow: hidden;
}

#component main {
	padding: 2em 0;
}

.content{
	margin-bottom: 50px;
}

.container-full #component main {
	padding: 0;
}

.solution,
.solution a {
	color: #fff;
	font-size: 0.8rem;
}

.solution a:hover {
	color: #fff;
}

button,
button.btn,
.btn {
	font-family: 'Roboto', sans-serif !important;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1px;
	padding: 0.7em 0.75em;
}

button.btn.btn-link,
.btn.btn-link {
	text-transform: none;
}

.btn-primary:focus,
.btn-primary.focus,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-danger:focus,
.btn-danger.focus,
.btn-success:focus,
.btn-success.focus,
.btn-info:focus,
.btn-info.focus{
	box-shadow: none;
}

select.form-control {
	padding: 0.5em 0.75em;
}

.form-control {
	padding: 0.8em 0.75em;
	border-color: #EDEDED;
	border-radius: 0;
	color: #999;
}

.form-control:focus {
	border-color: #ccc;
	color: #999;
}

.input-group-addon {
	background: #999999;
	border-color: #999999;
	color: #fff;
	border-radius: 0;
	width: 47px;
}

.header-wrapper {
	z-index: 200;
}

#footer-a-row {
	/* position: fixed;
	right: 30px;
	bottom: 30px; */
	z-index: 199;
}

#footer-a-row * {
	/* padding: 0;
	margin: 0; */
}

/*#footer-a-row .lg-text {
	font-size: 3.4rem;
}*/

#footer-b-row h2.moduleTitle {
	margin-bottom: 35px;
}

section.header.fix-to-top {
	background: none;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

section.header.fix-to-top.nonzero-position,
body.spacer-top section.header.fix-to-top{
	background: #fff;
	box-shadow: 0 2px 10px rgba(0,0,0,0.1)
}

section.header.fix-to-top.nonzero-position #header-a-row.section-container .row-container > .container-fluid,
body.spacer-top section.header.fix-to-top #header-a-row.section-container .row-container > .container-fluid {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

section.header.fix-to-top #header-a-row.section-container .row-container > .container-fluid {
	padding-top: 2.5em;
	padding-bottom: 2.5em;
}

/*.home_page #main-top-a-row {*/
/*        padding-top: 4em;*/
/*        padding-bottom: 4em;*/
/*}*/


.scroll-page {
    position: absolute;
    bottom: 50px;
    left: 50%;
    margin-left: -16px;
    z-index: 20;
    opacity: 0.8;
}

.scroll-page:hover {
	opacity: 1;
}

.scroll-page i {
	color: #fff;
	font-size: 50px;
	line-height: 10px;
}

/** modules fixes **/
h2.moduleTitle {
		font-weight: 700;
		color: #437ba5;
}
h2,
.item-text h2 {
	color: #437ba5;
    font-size: 26px;
    margin-bottom: 30px;
}
h2.moduleTitle strong {
		font-weight: 400;
}

h2.moduleTitle {
		margin-bottom: 80px;
}

.mod-item-heading {

	font-weight: 400;
}

p {

	font-weight: 300;
	font-size: 0.9rem;
	line-height: 1.5rem;
}

p strong,
p b {
	font-weight: 700;
}

ul li {
		font-size: 0.9rem;
		line-height: 1.6rem;
}

h3.mod-item-heading {
		line-height: 1.5rem;
}

h3.mod-item-heading a {
	font-size: 18px;
	font-weight: 500;
}

h3.mod-item-heading a:hover {
	text-decoration: none;
}

.mod-item-heading.text-dark .mod-item-title{
	color: #686868;
}

body .custom-urls {
	margin: 90px 0 0;
}

body #main-bottom-b-row .custom-urls {
	margin: 0;
}

.custom-urls .btn {
	padding: 0.8rem 0.75rem;
    font-size: 0.8rem;
}

#bottom-b-row .container > .row{
	/* align-items: center; */
}

#bottom-b-row{
	padding-top: 60px;
}

#footer-b-row{
	padding-top: 30px;
	padding-bottom: 50px;
}

/** cards **/
.card {
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		transition: all 0.3s ease;
}

.card .embed-responsive {
		display: none;
}

.items-block .card {
		border-radius: 0;
		height: 100%;
}

.items-block .card-image {
	position: relative;
	/*border-bottom: 1px solid rgba(0,0,0,0.1);*/
}

.items-block.cards-overlay-list .card .card-image {
	border: none;
}

.items-block .card {
	/*border: 1px solid rgba(46,107,169,1);
    border-bottom: 2px solid rgba(46,107,169,1);*/
    border: none;
}

.items-block a.card:hover {
	text-decoration: none;
}

.items-block a.card h2 {
	margin-bottom: 0;
}

.items-block.cards-overlay-list .card {
	border: none;
	box-shadow: none;
}

.items-block .card:hover {
		box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.items-block .card-time {

		display: block;
		margin-bottom: 5px;
}

.items-block .card-img-overlay {
		background: rgba(0,0,0,0.4);
		opacity: 0;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		transition: all 0.3s ease;
}

.items-block .card-img-overlay span {
		padding-bottom: 0;
		border-bottom: 1px solid #fff;
}

.items-block.cards-overlay-list .card .card-img-overlay span {
	border: none;
}

.items-block.cards-overlay-list .card .card-img-overlay span.read-more {
	text-transform: none;
	text-decoration: underline;
}

.items-block .card:hover .card-img-overlay {
		opacity: 1;
}

.items-block .card-label {
		position: absolute;
		right: 15px;
		bottom: -20px;
		width: 55px;
		height: 55px;
		line-height: 55px;
		text-align: center;
		border-radius: 50%;
		color: #fff;
		background: #7EA73F;
		font-size: 1.5em;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		transition: all 0.3s ease;
}

.box {
	padding: 15px;
	border: 1px solid #2E6BA9;
	border-bottom: 2px solid #2E6BA9;
	word-wrap: break-word;
}

.box h1 {
		padding-bottom: 10px;
		margin-bottom: 15px;
		border-bottom: 1px solid #ccc;
}

.tooltip.fade {
		opacity: 1;
}

#message-container {
		position: fixed;
		bottom: 0;
		right: 0;
		width: 500px;
		margin-bottom: 25px;
		margin-right: 25px;
		z-index: 999;
}

#system-message > div {
		background: #fff;
		box-shadow: 0 0 40px 0px rgba(0,0,0,0.2);
		border-radius: 5px;
		padding: 10px;
}

#system-message > div.alert.alert-message {
		background: #7EA73F;
		color: #fff;
}

#system-message > div.alert.alert-error {
		background: #d9534f;
		color: #fff;
}

#system-message > div.alert.alert-message .close {
		opacity: 1;
		font-size: 2rem;
}

#system-message > div.alert.alert-message .close:hover {
		cursor: pointer;
}

.border {
		border: 1px solid rgba(0, 0, 0, 0.1);
}

.icon-plus:before {
		font-family: 'FontAwesome';
		content: '\f067';
		color: #fff;
}

.icon-minus:before {
		font-family: 'FontAwesome';
		content: '\f068';
		color: #fff;
}


/* Front end editing **/
.edit-article-button {
	position: absolute;
	right: 0;
	top: -15px;
	/*width: 50px;*/
	/*height: 50px;*/
	line-height: 50px;
	text-align: center;
	z-index: 100;
}

.carousel-item .edit-article-button {
	top: 15px;
	right: 15px;
}

.edit-article-button a,
.edit-article-button > div.eb{
	color: #fff;
	padding: 0 17px;
	display: block;
	border-radius: 50%;
	background: #28a745;
	box-shadow: 0 2px 6px rgba(0,0,0,0.5);
}

.edit-article-button > div.eb:hover {
	cursor: pointer;
}

.edit-article-button a:hover,
.edit-article-button > div.eb:hover {
	color: #fff;
}

.edit-article-button.unpublished a,
.edit-article-button.unpublished > div.eb{
	background: #ffc107;
}


body.loged .header.fix-to-top {
	position: relative;
}

body.loged .showcase .module-content .page-header .filter {
		padding-top: 0;
}

body.loged .top.fix-to-top {
		z-index: 200;
}

body .btn-warning {
		color: #fff;
}

body .box .btn-primary {
		background: #333;
		border-color: #333;
}

#aside-right .box {
		margin-top: 2rem;
		margin-bottom: 2rem;
}

#aside-right .box h4 {
		font-size: 0.8rem;
		line-height: 1.5rem;
		font-weight: 700;
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: 1px solid rgba(0,0,0,0.1);
}

#aside-right .box p {
		font-size: 0.8rem;
		margin-bottom: 6px;
}

#aside-right .box p strong {
		font-weight: 700;
}

body .modal.show {
		z-index: 9999 !important;
}

body .modal-dialog {
		margin: 15px;
}

.modal-content button.close {
		position: absolute;
		right: -21px;
		top: -21px;
		opacity: 1;
		background: #000;
		font-size: 1rem;
		border-radius: 50%;
		width: 42px;
		height: 42px;
		padding: 5px;
		line-height: 32px;
		text-align: center;
		color: #fff;
		z-index: 999;
		box-shadow: 0px 0px 4px 0px rgba(255,255,255,0.4);
}

.modal-content button.close i {
		display: block;
		line-height: 32px;
}

.loader {
		margin: 30px;
		text-align: center;
}

#top-a-row #es .user-list {
		color: #ccc;
		line-height: 40px;
		padding: 10px 0 10px 15px;
}

#bottom-a-row {
	position: relative;
}

#bottom-a-row:before {
	/* content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 100%;
	background: #fff; */
}

.chzn-container {
	width: 100% !important;
}

.md-text {
	font-size: 14px;
}

h2.md-text {
	font-size: 24px;
}

h2.sm-text {
	font-size: 18px;
}

h3.md-text {
	font-size: 16px;
}

h3.md-text.moduleTitle {
	margin-bottom: 5px;
}

.text-dark.btn-primary {
	color: #fff !important;
}

.dropdown-toggle:after {
	display: none !important;
}

a.text-dark.btn-primary:hover,
a.text-dark.btn-primary:focus {
	color: #fff !important;
}

.fill .module-inner {
	padding: 30px;
	background: #F3F3F3;
}

.mod-intro-text.md-text p {
	font-size: 1rem;
}

#aside-right {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

#aside-right h2.moduleTitle {
	margin-bottom: 20px;
}

a.card:hover {
	text-decoration: none;
}

.contacts_page #main-top-a-row{
	margin-top: 20px;
	margin-bottom: 60px;
}

.contacts_mod .custom a{
	color: #727272;
	font-weight: 500;
}

.home_page #bottom-b-row {
	padding-top: 0;
}

#footer-a-row .comp-details .custom{
	padding: 10px 20px;
	border: 1px solid #929292;
	color: #fff;
}

.mod-section .custom p {
	font-size: 24px;
	font-weight: 600;
	line-height: 40px;
}

.mod-section-btn button {
	font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
	padding: 0.7em 0.75em;
	width: 200px;
	color: #686868;
	background-color: transparent;
	border: 2px solid #437ba5;
	border-radius: 10px;
}
.callus-btn a {
	font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
	padding: 0.7em 0.75em;
	width: 200px;
	border-radius: 10px;
}
.mod-section.moduletable {
	margin-left: auto;
	margin-right: auto;
}
h2.lg-text {
	font-size: 32px;
}
.aboutus_mod_title p {
    font-size: 43px;
    font-weight: 700;
    line-height: 46px;
    margin-top: 25px;
}
.products_mod h3.mod-item-heading a {
	font-size: 22px;
    font-weight: 500;
}
.aboutus_mod .split .custom-urls .btn.btn-outline-light {
	background-color: transparent;
	color: #fff;
}
.aboutus_mod_title {
	margin-top: auto;
	margin-bottom: auto;
}

