.slick-track {
	display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    align-items: center;
}

.slick-track .mod-item-image {
	margin: 0;
}

button.slick-arrow {
	background: no-repeat;
	font-size: 0;
	position: absolute;
	border: none;
	margin-top: -13px;
}

button.slick-arrow:focus {
	outline: none;
}

button.slick-arrow:before {
	font-family: 'FontAwesome';
	font-size: 26px;
	color: #727272;
}

button.slick-arrow.slick-prev {
	left: -15px;
	top: 50%;
}

button.slick-arrow.slick-prev:before {
	content: "\f104";
}

button.slick-arrow.slick-next {
	right: -15px;
	top: 50%;
}

button.slick-arrow.slick-next:before {
	content: "\f105";
}

.slick-container {
        position: relative;
}

.slick-container .edit-article-button {
	top: 0;
}
