@media only screen and (min-width: 1397px) {
	.projects-text .moduleTitle.mt-xl-5 {
		margin-top: 3rem !important;
	}
}
/* Extra extra Large Devices, Wide Screens */
@media only screen and (max-width : 1397px) {
	h3.mod-item-heading.sm-text a {
		font-size: 14px;
	}

	h3.mod-item-heading.sm-text a i.lg-text {
		font-size: 1.5rem;
	}

	body .footer-multi-col {
		min-width: 320px;
	}

	body .footer-multi-col .nav.horizontal>li{
		width: 100%;
	}

	body .footer-multi-col .nav.horizontal>li>a.nav-link {
		display: block;
		padding: 10px 0;
		border: none;
	}

/*	body .nav.flex-column>li>a {
		font-size: 0.7rem;
		line-height: 1rem;
	}*/

	.items-block.cards-overlay-list .card h3.mod-item-heading.sm-text {
		font-size: 0.8rem;
		line-height: 1rem;
		text-transform: none !important;
	}

	.contact-list h3.mod-item-heading {
		font-size: 0.8rem;
	}

	.stuff h2.md-text {
		font-size: 1.2rem;
	}

	.stuff .mod-intro-text.md-text p {
		font-size: 0.8rem;
	}

	body .showcase .module-content .page-header .page-header-title h1,
	body .showcase .module-content .page-header .page-header-title h2 {
		font-size: 30px;
	}

	body .nav.horizontal>li>a.nav-link {
		padding: .5em .7em;
	}

	body .card-body {
		padding: 1rem;
	}
	body .products_mod .items-block.services .mod-item-button {
		width: 55%;
	}
	.aboutus_mod_title p {
		margin-top: 0px;
		margin-bottom: 0px;
	}
}

/*@media (min-width: 1200px) {*/
/*        .container {*/
/*                width: 1140px;*/
/*        }*/
/*}*/

/* Extra Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
	
}

/* Large Devices, Desktops */
@media only screen and (max-width : 992px) {
	body .slide-fullscreen.carousel-fade .carousel-caption .mod-intro-text p {
		font-size: 28px;
	}

	body .slide-fullscreen.carousel-fade .carousel-caption .mod-intro-text p strong {
		font-size: 38px;
	}

	body .split p {
		line-height: 1.6rem;
	}

	#top-a-row .md-text {
		padding-left: 0;
	}

	#bottom-a-row:before {
		display: none;
	}

	.projects-text h2.sm-text {
		text-align: center !important;
	}

	.projects-text h2.sm-text:after {
		left: 50% !important;
		margin-left: -22.5px !important;
	}

	.projects-text p {
		text-align: center;
	}

	.items-block.cards-overlay-list .card .card-img-overlay {
		opacity: 1;
	}

	body .showcase .module-content .page-header .page-header-title h1,
	body .showcase .module-content .page-header .page-header-title h2 {
		font-size: 25px;
		line-height: 38px;
	}

	body #footer-a-row {
		position: relative;
		right: inherit;
		bottom: inherit;
	}

	body #footer-a-row .container {
		margin: 0 auto;
		/* padding: 15px 0; */
	}

	.fe-form .form-content .nav-tabs.nav li {
		height: 64px;
	}

	.fe-form .form-content .nav-tabs.nav li > a.nav-link {
		line-height: 64px;
	}

	body .contacts_home_mod .items-block .item.last-md-line-item{
		border: none;
	}

	.products_menu_mod .moduleTitle.text-right{
		text-align: left !important;
	}

	body #aside-right .nav>li>a{
		text-align: left !important;
	}

	body #aside-right .nav>li>a:before{
		content: none;
	}

	body .aboutus_mod .aboutus_mod{
		padding-left: 0;
		padding-right: 0;
	}
	.aboutus_mod_title .custom {
		text-align: center;
	}
	.mod-section .custom p {
		font-size: 16px;
		line-height: 30px;
	}
	#main-bottom-b-row .aboutus_mod_title .custom {
    	margin-bottom: 20px;
	}
}

/* Medium Devices, Tablets */
@media only screen and (max-width : 768px) {
	body .slide-fullscreen.carousel-fade .carousel-caption .mod-intro-text p {
		font-size: 22px;
	}

	body .slide-fullscreen.carousel-fade .carousel-caption .mod-intro-text p strong {
		font-size: 32px;
	}

	body .slide-fullscreen.carousel-fade .carousel-caption .mod-intro-text p{
		line-height: 35px;
	}

	h3.mod-item-heading.sm-text a {
		font-size: 16px;
	}

	body #main-bottom-b-row:before {
		display: none;
	}

	body .footer-multi-col {
		min-width: inherit;
	}

	body .copyright {
		text-align: center;
	}

	body .solution {
		margin-top: 10px;
		display: block;
		text-align: center;
	}

	body #top-a-row .md-text {
		font-size: 11px;
	}

	.breadcrumbs {
		display: none;
	}

	body .item-title.page-title {
		font-size: 1.5rem;
	}

	body .item-content h3.mod-item-heading {
		font-size: 20px;
	}

	body .item-content .item-image {
		width: 100%;
	}

	.items-block a.card h2 {
		text-align: left !important;
	}

	body .listing-item h2.item-title {
		font-size: 1rem;
	}

	body .attachments li a,
	body .manufacturer li a {
		font-size: 0.8rem;
	}

	body .showcase .module-content .page-header .page-header-title h1,
	body .showcase .module-content .page-header .page-header-title h2 {
		text-align: left !important;
	}

	.showcase .module-content .page-header .page-header-title h1 span,
	.showcase .module-content .page-header .page-header-title h2 span {
		display: inline-block !important;
		margin: 0 !important;
		font-size: 18px;
		line-height: 24px;
	}

	.fe-form .form-content .nav-tabs.nav li > a.nav-link {
		font-size: 16px;
	}

	.fe-form .tab-content .title span {
		font-size: 12px;
	}

	.fe-form .action-buttons .btn {
		padding: 0.3rem 0.5rem;
    	font-size: 0.8rem;
	}

	body .mce-ico,
	body .mce-menubtn.mce-btn-small span {
		font-size: 10px;
	}

	body .mce-btn-small button {
		padding: 0;
	}

	body .mce-menubar.mce-toolbar {
		display: none;
	}

	body .contacts_home_mod .items-block .item:not(.last-xl-line-item) {
		border: none;
	}
	
	body .products_mod .items-block.services .mod-item-button {
		width: 55%;
	}

	#bottom-b-row {
		padding-top: 0;;
	}
	body .slide-fullscreen.carousel-fade .carousel-caption .mod-intro-text p {
		padding: 0px 15px;
	}
	
	#main-bottom-b-row .aboutus_mod_title p {
		margin-top: 0px;
		margin-bottom: 0px;
	}
}

/* Small Devices, phone */
@media only screen and (max-width : 576px) {
	body h1.line,
	body h2.line,
	body h3.line {
		padding-bottom: 15px;
	}

	body h2.md-text {
		font-size: 20px;
	}

	body h2.moduleTitle {
		margin-bottom: 50px;
	}

	.services .item {
		margin-bottom: 15px;
	}

	.services .item .mod-intro-text {
		margin-bottom: 0;
	}

	.mod-image-footer {
		display: none;
	}

	.mod-container-card .module-inner {
		margin-bottom: 30px;
	}

	body .showcase .module-content .page-header .page-header-title h1,
	body .showcase .module-content .page-header .page-header-title h2 {
		font-size: 20px;
		line-height: 32px;
	}

	body .item-title.page-title {
		font-size: 1.3rem;
	}

	body .item-content h3.mod-item-heading {
		font-size: 16px;
		line-height: 24px;
	}

	body .attachments li a,
	body .manufacturer li a {
		font-size: 0.6rem;
	}

	body .attachments li,
	body .manufacturer li {
		line-height: 1.2rem;
	}

	body #aside-right .nav li.deeper > ul.nav-child li a,
	body #aside-right .nav li.deeper > ul.nav-child li span {
		font-size: 0.8rem;
		line-height: 1rem;
	}

	body .top_contacts_mod ul.nav.horizontal{
		justify-content: space-between;
	}
	.products_mod .items-block.services .item {
		margin-bottom: 25px;
	}
	#bottom-a-row .mod-section-btn .its-button,
	#bottom-a-row .callus-btn .its-button {
		text-align: center !important;
		margin-bottom: 20px;
	}
	
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
	body .fe-form .tab-content .title span {
		line-height: 18px;
	}

	body .fe-form .action-buttons .btn {
		width: 100%;
		margin-bottom: 1px;
	}

	body .slide-fullscreen .custom-urls .btn{
		padding: 10px 25px;
		font-size: 16px;
	}

	.products_list table{
		font-size: 11px;
	}


	body .slide-fullscreen.carousel-fade .carousel-caption .mod-intro-text p {
		font-size: 18px;
	}

	body .slide-fullscreen.carousel-fade .carousel-caption .mod-intro-text p strong {
		font-size: 32px;
	}

	body #top-a-row .nav>li>a, #top-a-row .nav>li>a{
		font-size: 10px;
	}

	body #top-a-row .nav-link i {
	    font-size: 16px;
	    margin-right: 5px;
	}

	body .content {
	    margin-bottom: 0;
	}

	body .showcase .module-content .page-header .page-header-title {
	    min-height: 160px;
	}
	.callus-btn a,
	.mod-section-btn button {
		font-size: 12px;
	}
}

/* Custom, iPhone Retina */
@media only screen and (max-width : 380px) {
	body #top-a-row .md-text {
		padding: 0.5em 0;
		font-size: 10px;
		text-align: left;
	}

	body .slide-fullscreen .custom-urls .btn{
		margin: 0 0 15px 0;
		width: 80%;
	}

	.facebook_link{
		display: none !important;
	}

	body .custom-urls {
	    margin: 15px 0 0;
	}

	body .slide-fullscreen .custom-urls .btn {
		padding: 15px 25px;
		font-size: 15px;
	}

	body .scroll-page{
		bottom: 30px;
	}
}
